@media only screen and (max-width: 600px) {
	.profile-card, .events-card {
		width: 90%;
	}

	.students-container {
		padding: 50px 20px;
	}
}

@media only screen and (max-width: 400px) {
	.react-select, .form-input {
		font-size: 16px;
	}
}

@media only screen and (max-width: 992px) {
	.students-filters-container {
		padding: 10px 0 !important;
	}

	.login-form {
		width: 80%;
	}
}