/*@font-face {
  font-family: 'Quicksand-Regular';
  src:
    local('Quicksand-Regular'),
    local('Quicksand-Regular'),
    url(../resources/fonts/Quicksand-Regular.ttf) format('truetype');
}*/

/*body {
    font-family: 'Quicksand-Regular';
}*/

main {
    background-color: #D0D0D0;
    min-height: 100vh;
}

/* LOGIN */

.login-container {
    min-height: 100vh;
    background-image: linear-gradient(to right, #FFFFFF , rgba(208,208,208, 0.6));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-form {
    min-height: 30%;
    width: 40%;
    max-width: 450px;
    background-color: #fff;
    border: 1px solid #FF3D00;
    border-radius: 5px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    margin: 40px;
}

.login-form-group {
    margin-bottom: 0px !important;
}

.login-input {
    border: 1px solid #D0D0D0 !important;
    margin: 10px 0;
    font-size: 18px;
}

.login-button {
    border: none !important;
    border-radius: 5px !important;
    font-size: 18px !important;
    background-color: #FF3D00 !important;
    color: #FFFFFF !important;
    margin-top: 10px !important;
    width: 100%;
}

/* HEADER */

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 80px;
    background-color: #FFF;
}

.header-logo img {
    width: 250px;
}

.header-item {
    padding: 25px;
    text-decoration: none;
    color: #FF3D00;
    cursor: pointer;
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
}

.header-item:hover {
    text-decoration: none;
    color: #002B59;
}

.header-item.guest:hover {
    text-decoration: none;
    color: #FFFFFF;
}

.header-item.active {
    color: #FFFFFF;
    background-color: #FF3D00;
}

.header-right {
    height: 100%;
    display: flex;
}

/* EVENTS */

.events-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 0px;
} 

.events-card {
    background-color: #FFFFFF;
    border-radius: 5px;
    width: 70%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.events-card.loader {
    justify-content: center;
}

.event-preview {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;
}

.event-details p {
    margin: 0;
    padding: 0;
}

.event-title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px !important;
}

.add-event-button {
    margin-bottom: 50px;
}

.event-preview-buttons {
    display: flex;
    flex-direction: column;
}

.event-preview-button {
    margin: 5px;
}


/* BUTTON */

.rounded-button {
    width: 170px !important;
    padding: 10px !important;
    border: none !important;
    border-radius: 5px !important;
    font-size: 14px !important;
    cursor: pointer;
}

.rounded-button.light-blue {
    color: #FFFFFF;
    background-color: #FF3D00;
    border: 1px solid #FF3D00 !important;
}

.rounded-button.light-blue:hover {
    color: #FF3D00;
    background-color: #FFFFFF;
}

.rounded-button.white-border {
    color: #FF3D00;
    background-color: #FFFFFF;
    border: 1px solid #FF3D00 !important;
}

.rounded-button.white-border:hover {
    color: #FFFFFF;
    background-color: #FF3D00;
    border: 1px solid #FF3D00 !important;
}

/* FORM */

.form-container {
    display: flex;
    justify-content: center;
    padding: 50px 0;
} 

.data-capture-form {
    width: 500px;
    background-color: #FFF;
    padding: 40px;
    border-radius: 5px;
    margin: 15px;
}

.form.loader {
    width: 500px;
    background-color: #FFF;
    padding: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-input {
    border: 1px solid #D0D0D0 !important;
    margin: 25px 0;
    font-size: 18px;
    overflow: visible;
}

.react-select {
    margin: 25px 0;
    font-size: 18px;
    color: #495057;
}

.checkbox-input {
    margin-top: 15px;
}

.checkbox-label {
    font-size: 14px;
    margin-bottom: 25px;
}

.blue-link {
    cursor: pointer !important;
}

.blue-link:hover {
    text-decoration: underline !important;
}

.submit-details-button {
    margin-top: 0px;
    width: 100% !important;
    background-color: #FF3D00;
}

.form-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.company-logo {
    height: 160px;
    border-radius: 50%;
    border: 1px solid #ccc;
}

.company-slogan {
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-style: italic;
    text-align: center;
}

/* STUDENTS */

.students-container {
    padding: 50px 100px;
} 

.students-table-container {
    background-color: #FFFFFF;
    border-radius: 5px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.students-filters-container {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.students-filters-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding-bottom: 20px;
}

.students-filters-top .filter {
    width: 100%;
    margin: 10px;
    //min-width: 190px;
}

.clear-filters-button {
    margin: 10px;
}

.students-card.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}

.email-student-button {
    background: #FF3D00;
}

.email-student-button:hover {
    background: #002B59;
}

.table-buttons {
    display: flex;
    justify-content: center;
}

/* PROFILE */

.profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
} 

.profile-card {
    background-color: #FFFFFF;
    border-radius: 5px;
    width: 50%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-card.loader {
    justify-content: center;
}

.edit-profile-button {
    margin: 20px;
}

.edit-profile-row {
    margin: 20px 10px;
}






